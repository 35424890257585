import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Intro from "../components/Intro";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const portfolio = (props) => {
  const { t } = useTranslation();
  const portfolioImageList = props.data.portfolioImageList.edges;
  const matches = useMediaQuery("(min-width:800px)");

  return (
    <Layout bodyClass="page-portfolios">
      <SEO title={t("ตัวอย่างผลงาน")} />
      <Intro
        imgAlt={t("ตัวอย่างผลงาน")}
        imgSrc="/images/hero/portfolio-hero.jpg"
        introImageAbsolute={true}
        introImageHideOnMobile={false}
      >
        <div>
          <h1>{t("ภาพตัวอย่างผลงาน")}</h1>
          <p>{t("ภาพตัวอย่างผลงานในอุตสาหกรรมต่างๆ")}</p>
        </div>
      </Intro>

      {portfolioImageList.length > 0 && (
        <div className="strip mt-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto mb-4">
                <h1 style={{ color: "#2f2f41", fontWeight: "bold" }}>
                  {t("ตัวอย่างผลงาน")}
                </h1>
              </div>
            </div>
          </div>
          <ImageList variant="masonry" cols={matches ? 3 : 2} gap={4}>
            {portfolioImageList.map(({ node }, index) => (
              <ImageListItem key={node.id}>
                <img
                  src={`/images/portfolio/${node.base}`}
                  alt={`portfolio image ${index}`}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      )}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-auto mb-4 mt-4">
            <Link
              className="button button-primary"
              to="https://www.facebook.com/NRFpowdercoating/photos"
            >
              {t("ตัวอย่างผลงานเพิ่มเติม ที่ Facebook")}
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query portfolioQuery($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    portfolioImageList: allFile(
      filter: { absolutePath: { regex: "/images/portfolio/" } }
    ) {
      edges {
        node {
          id
          base
          name
        }
      }
    }
  }
`;

export default portfolio;
